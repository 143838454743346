<template>
    <div class="contactUs">
        <div class="top-box">
            <p>联系我们</p>
        </div>
        <div class="img-box">
            <img src="../assets/image/icon5.jpg">
        </div>
        <div class="bakgrd-box">
            <div class="text-box">
                <p>山东锦智数字科技有限公司</p>
                <p>电话：13306443735</p>
                <p>地址：山东省济南市槐荫区齐州路2066号善信大厦1712</p>
            </div>
        </div>
        
    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    }
}
</script>
<style lang="less" scoped>
.contactUs{
    width: 100%;
    height: 100%;
}
.top-box{
    width: 100%;
    height: 80px;
    background-color: #f5f5f5;
}
.top-box>p{
    line-height: 80px;
    font-size: 24px;
    color: #008CBF;
    margin: 0;
    padding: 0 0 0 50px;
}
.bakgrd-box{
    width:100%;
    height:270px;
    background-color: #F3F8FE;
    margin: 30px 0 0 0;
    overflow: hidden;
}
.text-box{
    width: 600px;
    margin: 53px auto;
}
.img-box{
    width:100%;
    height: 400px;
}
.img-box>img{
    width:100%;
    height:400px;
    background-size: 100% 100%;
}
.text-box>p:nth-child(1){
    font-size: 30px;
    padding: 0 0 0 50px;
}
.text-box>p:nth-child(2),.text-box>p:nth-child(3){
    font-size: 20px;
    text-align: left;
    padding: 0 0 0 50px;
}


</style>